//iPad basic
@media only screen and (max-width: 1024px) {

	// 	//Wrapper
	// .wrapper{width: 950px;}
	//
	// //Nav wrapper
	// nav#navholder > .wrapper{width: 100%;}
	// ul.nav{width: 100%;}
	//
	//
	// //Mainholder
	// #mainholder{width: 950px;}
	//
	// //Contents
	// .Content1Column{width: 950px;}

}

@media only screen and (max-width: 990px) { 

	// ----------------------- PHOTO BUTTONS

	ul#photo-btns { height: auto;
		li { display: block; width: 100%; float: none; height: 300px;

			a { display: block; width: 100%; height: 100%; position: relative; overflow: hidden; 
				
				span { left: 0; right: 0; margin: 0 auto; text-align: center; }
			}
		}
	}
}

// Mobile Version

@media only screen and (max-width: 770px) {

	// Body

	body {
	 	min-width: 0;
	 	overflow-x: hidden;
	}

	// Global

	img {
		max-width: 100%;
		height: auto;
	}
	table {
		max-width: 100%;
	}

	//Responsive tables
	table.tableborder{width: 100%; overflow: scroll; white-space: nowrap; table-layout: fixed;position: relative;
		&:before{content: "";width: 30px;height: 100%;position: absolute;top: 0;right: -1px;
			background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(0,0,0,0.2) 100%);
			background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(0,0,0,0.2) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#33000000',GradientType=1 );
		}
		tbody{width: 100%; float: left; white-space: normal; overflow-x: scroll;}
	}

	// Wrappers
	.wrapper {
		width: 100%;
	}

	// Calendar

	#calendar {
	    .fc-header td {
	        display: block;
	        width: 100%;
	    }
	    h2 {} .fc-button-content {
	        font-family: Arial;
	    }
	    .fc-header-right {
	        text-align: left;
	    }
	    .fc-header-center {
	        text-align: left;
	    }
	}

	// Forms

	form div input[type="text"], form div textarea, form span textarea, form div input[type="textarea"], form div input[type="password"] {
	    width: 200px;
	}
	form div span.button {
	    padding-left: 0;
	}

	//Search Form
	#FS{ display: none;}
	.translate { display: none; }

	// Header
	header{ height: auto; overflow: hidden;
		.wrapper{padding: 0;}
	}

	img#logo {  margin: 60px auto 20px; float: none; display: block; max-width: 85%}

	//Slider
	#slider{ }

	#welcome {}


	// Mainholder
	#mainholder{width: 100%;padding: 0;}

	// Contents
	.Content1Column,
	.Content2Column,
	.Content3Column{width: 80%; padding: 5%; margin: 5%;}

	// Col Left and Right
	.ColumnLeft,
	.ColumnRight{width: 80%; padding: 5%; margin: 5%;}

	//Home page alert
	.modal-home{
		.message-modal{width: 90%; margin: 0 5%; left: 0; top: 20%; padding: 0;
			.modal-content{
				.modal-text{padding: 3%;}
				a.close-modal{position: absolute; top: 3%; right: 3%; background: #fff; padding: 3px; border-radius: 0px 0px 0px 7px;}
			}
		}
	}

	// Footer
	footer{ height: auto;
		.wrapper{width: 90%;padding: 5%;
			p { text-align: center; margin: 10px auto; display: block;}
			a { word-break: break-all;}
		}
	}

	#awards { display: none; }

	.sub-footer  { height: auto; line-height: normal; padding: 5px 0;
	p { text-align: center; margin: 5px auto; float: none;}
	#credit { float: none; }
}
}


@media only screen and (max-width: 400px) {


}
